'use client';

import React from 'react';

import HomeHero from '~/Layout/HomeHero';
import NavBar from '~/Layout/NavBar';

export default function Page() {
  return (
    <div>
      <NavBar />
      <HomeHero />
    </div>
  );
}
