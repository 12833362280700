'use client';

import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { BsFillPlayFill } from 'react-icons/bs';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import useAuth from '#/providers/W3Provider/useAuth';
import { useRedirectToDashboard } from '#/providers/W3Provider/useRedirect';

import LoadingIndicator from '~/ui/LoadingIndicator';

function HomeHero() {
  const router = useRouter();

  const { authStatus } = useAuth();

  const redirect = authStatus === 'authenticated';
  useRedirectToDashboard(redirect);

  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  // const thumbnailUrl = '/assets/images/video-thumbnail.png';
  const thumbnailUrl = '/assets/images/video-thumbnail-2.png';

  // <iframe src="https://player.vimeo.com/video/851506753?h=65d6ed8189" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  // const videoUrl = '/assets/demoVideo/Automating_Twitter_with_SimpFi.ai_.mp4';
  // 'https://link.storjshare.io/raw/jvq2c6sdyxwemzeg4lvxm2crs3oq/simplifi-dev%2FAutomating_Twitter_with_SimpFi.ai_.mp4';
  // const videoUrl = 'https://player.vimeo.com/video/851506753';
  const videoUrl =
    'https://link.storjshare.io/raw/jwdlkmbauhmn66jyf5rt7l7q2xdq/simplifi-dev/DATA_ROOM%2FMarketing%20Videos/Propaganda%20Demo%20(take%202).mp4';

  function handleClickOnMobile() {
    setIsLoading(true);
    router.push('/mobile-cta-splash');
  }

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(
        window.matchMedia('(max-device-width: 767px)').matches ||
          window.matchMedia('(max-device-height: 600px)').matches ||
          window.matchMedia('(max-width: 767px)').matches,
      );
    };

    // Check on initial load
    checkMobile();

    // Add event listener
    const mediaQueryList = window.matchMedia('(max-width: 767px)');
    mediaQueryList.addEventListener('change', checkMobile);

    // Clean up event listener
    return () => mediaQueryList.removeEventListener('change', checkMobile);
  }, []);

  // useEffect(() => {
  //   // Lock the screen orientation to "portrait"
  //   const lockOrientation = () => {
  //     try {
  //       if (screen.orientation && screen.orientation.lock) {
  //         screen.orientation.lock('portrait').catch((err) => {
  //           console.error('Failed to lock orientation:', err);
  //         });
  //       } else if (screen.orientation && screen.orientation.unlock) {
  //         screen.orientation.unlock();
  //       }
  //     } catch (err: any) {
  //       console.error('Failed to lock orientation:', err);
  //     }
  //   };

  //   // Lock orientation on initial load
  //   lockOrientation();
  // }, [isMobile]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsLoaded(true);
    };

    // Check if the initial HTML document has been loaded
    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      // Add the event listener for DOMContentLoaded
      document.addEventListener('DOMContentLoaded', handlePageLoad);

      // Add the event listener for window.onload
      window.addEventListener('load', handlePageLoad);

      // Clean up the event listeners to avoid memory leaks
      return () => {
        document.removeEventListener('DOMContentLoaded', handlePageLoad);
        window.removeEventListener('load', handlePageLoad);
      };
    }
  }, []);

  if (typeof window === 'undefined') {
    return (
      <div className=" mt-8 w-full p-2 flex flex-col justify-center items-center text-center">
        <div style={{ height: 'calc(85vh - 16rem)' }}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  if (isLoading || !isLoaded) {
    return (
      <div className=" mt-8 w-full p-2 flex flex-col justify-center items-center text-center">
        <div style={{ height: 'calc(85vh - 16rem)' }}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <>
      {/* MOBILE VIEW */}
      <div
        className="xl:hidden max-sm:h-[70vh] sm:pb-12 mt-8 w-full p-2 flex flex-col justify-center items-center text-center"
        // style={{ maxHeight: `calc(${window.innerHeight}px - 16rem)` }}
      >
        <div className="mb-5">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight mb-2 text-white text-center">
            <span className="text-[#30E000]">Visualize </span>Anything,
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-white text-center">
            <span className="text-[#30E000]">Automate </span>Everything.
          </h1>
        </div>
        <div className="max-sm:hidden sm:text-md md:text-lg lg:text-xl mb-5 text-white tracking-tight">
          <p>No-code AI-powered workflow automation.</p>
        </div>
        <div className="flex justify-center items-center my-5">
          {isPlaying ? (
            <>
              {/* <iframe
                src={videoUrl}
                width="333"
                height="222"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                onClick={() => setIsPlaying(false)}
              ></iframe> */}
              <div className="sm:hidden">
                <video
                  style={{
                    width: '318px',
                    height: '212px',
                    border: '1px solid #333',
                  }}
                  controls
                  autoPlay={false}
                  onClick={() => setIsPlaying(false)}
                  className="rounded-xl border border-[#333]"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
              <div className="max-sm:hidden md:hidden">
                <video
                  style={{
                    width: '480px',
                    height: '320px',
                    border: '1px solid #333',
                  }}
                  controls
                  autoPlay={false}
                  onClick={() => setIsPlaying(false)}
                  className="rounded-xl border border-[#333]"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
              <div className="max-md:hidden">
                <video
                  style={{
                    width: '640px',
                    height: '374px',
                    border: '1px solid #333',
                  }}
                  controls
                  autoPlay={false}
                  onClick={() => setIsPlaying(false)}
                  className="rounded-xl border border-[#333]"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            </>
          ) : (
            <>
              <div
                className="max-sm:w-[318px] max-sm:h-[212px] sm:w-[480px] sm:h-[320px] md:w-[640px] md:h-[374px] bg-[#ffffff/20] rounded-xl flex justify-center items-center bg-cover bg-center border border-[#333]"
                style={{ backgroundImage: `url(${thumbnailUrl})` }}
                onClick={() => setIsPlaying(true)}
              >
                <BsFillPlayFill className="text-white w-10 h-10" />
              </div>
            </>
          )}
        </div>
        <div className="md:flex flex-row gap-6 items-center justify-center mt-7 mb-5">
          <>
            <button
              id="learn-more-button"
              className="sm:hidden bg-[#26B400] text-white py-2 px-5 rounded-full font-medium"
              onClick={handleClickOnMobile}
            >
              GET ACCESS NOW!
            </button>
            <ConnectButton.Custom>
              {({ openConnectModal, mounted, authenticationStatus }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                if (authenticationStatus === 'authenticated') {
                  router.push('/dashboard/workspaces');
                }
                return (
                  <div
                    {...(!ready && {
                      'aria-hidden': true,
                      style: {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    <button
                      id="learn-more-button"
                      className="bg-[#26B400] text-primary text-lg py-4 px-6 rounded-lg font-medium hover:scale-105 ease-in duration-200 cursor-pointer"
                      onClick={openConnectModal}
                    >
                      GET ACCESS NOW!
                    </button>
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </>
        </div>
        <h3 className="text-2xl md:text-3xl font-bold tracking-tight text-[#30E000] text-center">
          <span className="text-white text-xl md:text-2xl">
            Pre-Alpha Access Available Now
          </span>
        </h3>
      </div>

      {/* DESKTOP VIEW */}
      <div className="max-xl:hidden flex flex-col justify-center items-center w-full h-[70vh] my-auto">
        <div className="max-w-max m-auto px-2 py-16">
          <div className="grid grid-cols-2 space-between gap-12 px-12 mt-16">
            {isPlaying ? (
              <div className="w-[640px] h-[374px] bg-[#ffffff/20] flex justify-center items-center">
                <video
                  style={{ width: '100%', height: '100%' }}
                  controls
                  autoPlay
                  onClick={() => setIsPlaying(false)}
                  className="rounded-xl border border-[#333]"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <>
                <div
                  className="w-[640px] h-[374px] bg-[#ffffff/20] rounded-xl flex justify-center items-center bg-cover bg-center hover:opacity-[0.77] hover:cursor-pointer border border-[#333]"
                  style={{
                    backgroundImage: `url(${thumbnailUrl})`,
                    width: '640px',
                    height: '374px',
                  }}
                  onClick={() => setIsPlaying(true)}
                >
                  <BsFillPlayFill className="text-white w-16 h-16 hover:scale-[1.03] ease-in duration-100" />
                </div>
              </>
            )}
            <div className="flex flex-col items-center justify-center space-y-12">
              <div className="flex flex-col items-center justify-between space-y-6">
                <div className="flex flex-col items-center justify-between space-y-5">
                  <h1 className="text-5xl font-bold tracking-tight text-primary text-center">
                    <span className="text-[#30E000]">Visualize </span>Anything,
                  </h1>
                  <h1 className="text-5xl font-bold tracking-tight text-primary text-center">
                    <span className="text-[#30E000]">Automate </span>Everything.
                  </h1>
                </div>
                <div className="text-xl text-white tracking-tight">
                  No-code AI-powered workflow automation.
                </div>
              </div>
              <div className="flex flex-col items-center justify-between space-y-6">
                <h3 className="text-3xl font-bold tracking-tight text-[#30E000] text-center">
                  <span className="text-2xl text-primary font-semibold">
                    Pre-Alpha Access Available Now
                  </span>
                </h3>
                <ConnectButton.Custom>
                  {({ openConnectModal, mounted, authenticationStatus }) => {
                    const ready = mounted && authenticationStatus !== 'loading';
                    if (authenticationStatus === 'authenticated') {
                      router.push('/dashboard/workspaces');
                    }
                    return (
                      <div
                        {...(!ready && {
                          'aria-hidden': true,
                          style: {
                            opacity: 0,
                            pointerEvents: 'none',
                            userSelect: 'none',
                          },
                        })}
                      >
                        <button
                          id="learn-more-button"
                          className="bg-[#26B400] text-primary text-lg py-4 px-6 rounded-lg font-medium hover:scale-105 ease-in duration-200 cursor-pointer"
                          onClick={openConnectModal}
                        >
                          GET ACCESS NOW!
                        </button>
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHero;
