import Spinner from './spinner';

export default function LoadingIndicator() {
  return (
    <div className="bg-transparent h-full w-full flex flex-row justify-center items-center">
      <main className="m-auto flex flex-col justify-center items-center space-y-2">
        <Spinner />
        <p className="text-primary">Hang on, this won&apos;t take long...</p>
      </main>
    </div>
  );
}
